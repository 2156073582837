@font-face {
  font-family: "Kontora";
  src: url("../fonts/Kontora-Thin.woff2") format("woff2"), url("../fonts/Kontora-Thin.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "Kontora";
  src: url("../fonts/Kontora-Light.woff2") format("woff2"), url("../fonts/Kontora-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Kontora";
  src: url("../fonts/Kontora-Regular.woff2") format("woff2"), url("../fonts/Kontora-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Kontora";
  src: url("../fonts/Kontora-Medium.woff2") format("woff2"), url("../fonts/Kontora-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Kontora";
  src: url("../fonts/Kontora-Thin.woff2") format("woff2"), url("../fonts/Kontora-Thin.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Kontora";
  src: url("../fonts/Kontora-Bold.woff2") format("woff2"), url("../fonts/Kontora-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Kontora";
  src: url("../fonts/Kontora-ExtraBlack.woff2") format("woff2"), url("../fonts/Kontora-ExtraBlack.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "Kontora";
  src: url("../fonts/Kontora-Black.woff2") format("woff2"), url("../fonts/Kontora-Black.woff") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.woff2") format("woff2"), url("../fonts/Montserrat-Black.woff") format("woff");
  font-weight: 900;
}

:root {
  --font-family: "Kontora", sans-serif;
  --second-family: "Montserrat", sans-serif;
  --primary-color: #62DDA9;
  --orange-color: #F4A749;
  --white-color: #FFFFFF;
  --dark-color: #2A2A2A;
  --lite-color: #DBECFF;
  --blue-color: #4B84C3;
  --dark-blue-color: #1D426C;
  --lite-blue-color: #B0D0FF;
  --grey-color: #DADADA;
  --error-color: #FF2424;
  --bg-gradient: linear-gradient(180deg, #002248 0%, #76b0f2 86%, #daead2 100%);
  --start-bg-color: #101010;
}

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

body {
  line-height: 1;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

.container {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

body {
  font-family: var(--font-family);
  background: var(--bg-gradient);
  font-size: 14px;
  line-height: 120%;
  color: var(--white-color);
}

body.locked {
  overflow: hidden;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
  min-height: 100vh;
  padding: 20px;
  overflow: hidden;
}

.wrapper--start {
  background-color: var(--start-bg-color);
}

.title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 32px auto 0;
}

.btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  min-height: 52px;
  padding: 16px;
  -webkit-box-shadow: 0 8px 20px 0 rgba(17, 152, 95, 0.4);
          box-shadow: 0 8px 20px 0 rgba(17, 152, 95, 0.4);
  background-color: var(--primary-color);
  border-radius: 100px;
  border: 0;
  outline: 0;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}

.footer__btn_delete {
  box-shadow: unset;
  background: unset;
  color: red;
}

.btn-outline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  min-height: 52px;
  padding: 16px;
  border: 0;
  outline: 0;
  border: 1px solid var(--white-color);
  background-color: transparent;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}

.btn-disabled {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  min-height: 52px;
  padding: 16px;
  -webkit-box-shadow: 0 8px 20px 0 rgba(166, 166, 166, 0.4);
          box-shadow: 0 8px 20px 0 rgba(166, 166, 166, 0.4);
  background-color: var(--grey-color);
  border-radius: 100px;
  border: 0;
  outline: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}
.btn-enable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  min-height: 52px;
  padding: 16px;
  -webkit-box-shadow: 0 8px 20px 0 rgba(166, 166, 166, 0.4);
          box-shadow: 0 8px 20px 0 rgba(166, 166, 166, 0.4);
  background-color: #62dda9;
  border-radius: 100px;
  border: 0;
  outline: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}

.btn-small-height {
  min-height: 34px;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
}

.primary-color {
  color: var(--primary-color);
}

.orange-color {
  color: var(--orange-color);
}

.error-color {
  color: var(--error-color);
}

.header {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.nav__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
  margin: 32px 0;
}

.form__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}

.form__label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 8px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: var(--white-color);
}
.form__codes.error input {
  border: 1px solid var(--error-color);
}

.form__input-gorup {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}

.form__input-gorup--token .form__input {
  padding: 10px 44px 10px 50px;
  font-weight: 800;
  font-size: 14px;
}

.form__input-token {
  position: absolute;
  left: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.form__input {
  padding: 10px 44px 10px 20px;
  width: 100%;
  height: 50px;
  border: 2px solid transparent;
  outline: 0;
  border-radius: 12px;
  background-color: #DBECFF;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: var(--dark-color);
}

.form__input::-webkit-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: var(--dark-color);
}

.form__input::-moz-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: var(--dark-color);
}

.form__input:-ms-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: var(--dark-color);
}

.form__input::-ms-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: var(--dark-color);
}

.form__input::placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: var(--dark-color);
}

/*.form__input:active,*/
/*.form__input:focus {*/
/*  border-color: var(--primary-color);*/
/*}*/

.form__input[type=password] {
  font-size: 24px;
}

.form__input-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 10px;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.form__input-btn span {
  font-weight: 500;
  font-size: 13px;
  color: #929292;
}

.form__camera-btn {
  background-color: transparent;
  border: 0;
  outline: 0;
}

.form__sub {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 8px;
  font-size: 11px;
  line-height: 100%;
  text-decoration: underline;
  color: var(--white-color);
}

.form__sub.form__label {
  text-decoration: none;
}

.form__sub.primary-color {
  color: var(--primary-color);
}

.form__comission {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px;
  border-radius: 12px;
  background-color: var(--blue-color);
  text-align: center;
  font-size: 11px;
  line-height: 100%;
  color: var(--white-color);
}

.form__qr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 30px;
  border-radius: 12px;
  background-color: var(--lite-color);
}

.form__input-radio {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -100;
  opacity: 0;
}

.form__input-radio:checked ~ .form__input-radio-label .form__input-radio-decor::before {
  opacity: 1;
}

.form__input-radio-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
}

.form__input-radio-decor {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid var(--primary-color);
  background-color: var(--white-color);
}

.form__input-radio-decor::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--primary-color);
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.form__input-radio-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: var(--white-color);
}

.form__codes {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}

.form__code {
  width: 100%;
  height: 76px;
  background-color: var(--white-color);
  border-radius: 6px;
  border: 0;
  outline: 0;
  padding: 10px 4px;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 36px;
}

.form__file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}

.form__file-picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 12px;
  background-color: var(--lite-color);
  overflow: hidden;
}

.form__file-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}

.form__file-input {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -100;
  opacity: 0;
}

.form__file-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
  font-family: var(--font-family);
}

.form__file-btn {
  max-width: 160px;
}

.form__file-text {
  font-size: 10px;
  line-height: 100%;
  color: var(--white-color);
}

.form-mb-12 {
  margin-bottom: 12px;
}

.form-mb-0 {
  margin-bottom: 0;
}

.tokens {
  padding: 20px;
  border-radius: 12px;
  background-color: var(--lite-color);
}

.tokens__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}

.tokens__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.tokens__item-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.tokens__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}

.tokens__icon {
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 100%;
  overflow: hidden;
}

.tokens__texts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}

.tokens__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #7187a8;
}

.tokens__text--bold {
  font-weight: 800;
  font-size: 14px;
  color: var(--dark-color);
}

.tokens__texts--right {
  text-align: right;
}

.tokens__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  margin-bottom: 12px;
}

.tokens__actives {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}

.tokens__actives-text {
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  color: var(--dark-color);
}

.tokens__actives-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 100%;
  background-color: var(--dark-color);
  overflow: hidden;
}

.tokens__history {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 800;
  font-size: 16px;
  line-height: 120%;
  color: var(--dark-color);
  border-bottom: 1px solid var(--dark-color);
}

.selects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  margin-bottom: 8px;
}

.selects .select {
  margin: 0;
}

.selects .select__icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.selects .select__token-icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.select {
  position: relative;
  z-index: 2;
  margin: 12px 0;
}

.select.open {
  z-index: 3;
}

.select.open .select__tokens {
  top: 20px;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  pointer-events: visible;
  opacity: 1;
}

.select.open .select__sorts {
  top: 20px;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  pointer-events: visible;
  opacity: 1;
}

.select.open .select__btn-arrow {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.select.open .select__btn {
  z-index: 3;
}

.select__label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 100%;
  color: var(--white-color);
}

.select__selected {
  width: 100%;
  min-height: 50px;
  padding: 6px 20px;
  border-radius: 12px;
  background-color: var(--lite-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.select__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}

.select__icon {
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 100%;
  overflow: hidden;
}

.select__text {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: var(--dark-color);
}

.select__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.select__btn-arrow {
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  -o-transition: transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}

.select__tokens {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background-color: var(--lite-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
  overflow-y: auto;
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  -webkit-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.select__token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.select__token-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.select__token-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}

.select__token-icon {
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 100%;
  overflow: hidden;
}

.select__token-texts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}

.select__token-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: var(--dark-color);
}

.select__sorts {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 20px 20px 0;
  border-radius: 12px;
  background-color: var(--lite-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  -webkit-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.select__sort {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.select__sort:last-child .select__sort-label {
  border-bottom: transparent;
}

.select__sort:has(.select__sort-input:checked) .select__sort-checked {
  opacity: 1;
}

.select__sort-group {
  width: 100%;
}

.select__sort-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid var(--lite-blue-color);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: var(--dark-color);
}

.select__sort-input {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -100;
  opacity: 0;
}

.select__sort-checked {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.start {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  row-gap: 20px;
  padding: 40px 0;
  height: calc(100vh - 40px);
}

.start::before {
  content: "";
  position: absolute;
  top: -200px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 375px;
  height: 375px;
  background-color: #77b1f1;
  border-radius: 100%;
  -webkit-filter: blur(150px);
          filter: blur(150px);
  z-index: -1;
}

.start::after {
  content: "";
  position: absolute;
  bottom: -260px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 290px;
  height: 290px;
  background-color: var(--primary-color);
  border-radius: 100%;
  -webkit-filter: blur(100px);
          filter: blur(100px);
  z-index: -1;
}

.start__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.start__title {
  margin-bottom: 12px;
  text-align: center;
  font-weight: 500;
  font-size: 42px;
  line-height: 100%;
  color: var(--white-color);
}

.start__text {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--white-color);
}

.offchain__text {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--whitec-olor);
}

.offchain__text span {
  font-weight: 800;
}

.offchain__text:not(:last-child) {
  margin-bottom: 4px;
}

.offchain__text--lite {
  color: var(--lite-color);
}

.deposit__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 12px;
  margin: 32px 0;
}

.deposit__head-row {
  display: grid;
  grid-template-columns: 80px 140px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.deposit__head-label {
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  color: var(--white-color0);
}

.deposit__head-value {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: var(--primary-color);
}

.swap .select {
  margin: 0;
}

.swap__label {
  margin-bottom: 16px;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  color: var(--white-color);
}

.swap__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8px;
}

.swap__icon {
  display: block;
  margin: 16px auto;
}

.settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.settings .select {
  margin: 0;
}

.settings__group {
  padding: 20px 0;
}

.settings__group:first-child {
  padding-top: 0;
}

.settings__group:not(:last-child) {
  border-bottom: 1px solid var(--lite-blue-color);
}

.settings__group .form__gorup:not(:last-child) {
  margin-bottom: 14px;
}

.settings__group .form__input-gorup:not(:last-child) {
  margin-bottom: 14px;
}

.settings__verificaty-label {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: var(--white-color);
}

.settings__verificaty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}

.settings__verificaty-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: var(--white-color);
}

.settings b.settings__verificaty-text {
  font-weight: 700;
}

.transactions {
  padding: 20px 20px 20px 10px;
  border-radius: 12px;
  background-color: var(--lite-color);
}

.transactions__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}

.transactions__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.transactions__item-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.transactions__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}

.transactions__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  overflow: hidden;
}

.transactions__texts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}

.transactions__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: var(--dark-color);
}

.transactions__text b {
  font-weight: 800;
  font-size: 14px;
}

.transactions__times {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #7187a8;
}

.transactions__sums {
  text-align: right;
}

.transactions__sum {
  font-weight: 800;
  font-size: 14px;
  color: var(--dark-color);
}

.transactions__action {
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
}

.autoconvert {
  padding: 30px 20px;
  border-radius: 12px;
  background-color: var(--lite-color);
}

.autoconvert--outline {
  padding: 0;
  border-radius: 0;
  background-color: transparent;
}

.autoconvert + .form__qr {
  margin-top: 10px;
}

.autoconvert .form__label {
  color: var(--dark-color);
}

.autoconvert .form__input {
  background-color: var(--white-color);
}

.autoconvert__label {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: var(--dark-color);
}

.autoconvert__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 14px;
  padding: 20px 0;
}

.autoconvert__group:last-child {
  padding-bottom: 0;
}

.autoconvert__group:not(:last-child) {
  border-bottom: 1px solid var(--lite-blue-color);
}

.autoconvert__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.autoconvert__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: var(--dark-color);
}

.autoconvert__text--center {
  width: 100%;
  text-align: center;
}

.autoconvert__text--bold {
  font-weight: 800;
  font-size: 16px;
}

.autoconvert__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}

.autoconvert__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  padding: 14px 20px;
  border-radius: 12px;
  background-color: var(--lite-color);
}

.autoconvert__tokens {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.autoconvert__token:nth-child(2) {
  margin-left: 16px;
  margin-top: -10px;
}

.autoconvert__item-texts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8px;
}

.autoconvert__item-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: var(--dark-color);
}

.autoconvert__item-text b {
  font-weight: 800;
  font-size: 20px;
}

.autoconvert__item-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: auto;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.verification__percent {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 240px;
  height: 240px;
  margin: 60px auto 34px;
}

.verification__percent-cirkle {
  position: relative;
  width: 240px;
  height: 240px;
  border: 30px solid #1D426C;
  border-radius: 100%;
}

.verification__percent-cirkle-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100%;
  border: 30px solid var(--primary-color);
  border-radius: 100%;
}

.verification__percent-sum {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: 800;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}

.verification__text {
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}

.verification__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8px;
}

@-webkit-keyframes progress {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@keyframes progress {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

[role=progressbar] {
  --percentage: var(--value);
  --primary: #62DDA9;
  --secondary: #1D426C;
  --size: 240px;
  -webkit-animation: progress 2s 0.5s forwards;
          animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
}

[role=progressbar]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(var(--primary) calc(var(--percentage) * 1%), var(--secondary) 0);
  -webkit-mask: radial-gradient(white 55%, transparent 0);
          mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 50%, #000 0);
  -webkit-mask-mode: alpha;
}

[role=progressbar]::after {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  font-weight: 800;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  color: var(--white-color);
}

.detalies {
  padding: 40px 24px 32px 24px;
  border-radius: 12px;
  background-color: var(--lite-color);
}

.detalies__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 16px;
  margin-bottom: 62px;
  text-align: center;
}

.detalies__head-label {
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  color: var(--dark-color);
}

.detalies__head-sum {
  font-weight: 800;
  font-size: 36px;
  line-height: 100%;
  color: var(--dark-color);
}

.detalies__rows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 14px;
}

.detalies__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.detalies__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: var(--dark-color);
}

.detalies__text.primary-color {
  font-weight: 800;
  font-size: 14px;
  color: var(--primary-color);
}

.detalies__text.orange-color {
  font-weight: 800;
  font-size: 14px;
  color: var(--orange-color);
}

.detalies a.detalies__text {
  text-decoration: underline;
}

.detalies__contact {
  margin-top: 24px;
}

.detalies__contact .form__input {
  background-color: var(--white-color);
}

.status {
  padding: 24px;
  border-radius: 12px;
  background-color: var(--lite-color);
}

.status + .status {
  margin-top: 20px;
}

.status--primary-border {
  border: 5px solid var(--primary-color);
}

.status__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 14px;
     -moz-column-gap: 14px;
          column-gap: 14px;
  margin-bottom: 24px;
}

.status__head-text {
  font-weight: 800;
  font-size: 18px;
  line-height: 100%;
  color: var(--dark-color);
}

.status__head-text.primary-color {
  color: var(--primary-color);
}
.green-color {
  color: var(--primary-color);
}

.status__head-text.error-color {
  color: var(--error-color);
}

.status__head-text--center {
  text-align: center;
  width: 100%;
}

.status__rows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 12px;
}

.status__row {
  display: grid;
  grid-template-columns: 120px 1fr;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}

.status__text {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: var(--dark-color);
}

.status__text--bold {
  font-weight: 800;
}

.balanse {
  margin: 0 0 40px;
}

.balanse__head {
  position: relative;
  padding: 28px 0 120px 0;
  background-image: url("../img/main/main-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 375px 188px;
}

.balanse__head::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  width: 100px;
  height: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  background-image: url("../img/main/main-left.png");
  background-repeat: no-repeat;
}

.balanse__head::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  width: 58px;
  height: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  background-image: url("../img/main/main-right.png");
  background-repeat: no-repeat;
}

.balanse__label {
  margin-bottom: 12px;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: var(--white-color);
}

.balanse__sums {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
}

.balanse__sum {
  font-weight: 800;
  font-size: 42px;
  line-height: 66%;
  color: var(--white-color);
}

.balanse__select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  background-color: transparent;
  padding: 4px 8px;
  border: 1px solid var(--white-color);
  border-radius: 100px;
  cursor: pointer!important;
}

.balanse__select-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: var(--white-color);
}

.balanse__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.balanse__btn-arrow {
  -webkit-filter: brightness(0) saturate(100%) invert(97%) sepia(8%) saturate(67%) hue-rotate(339deg) brightness(114%) contrast(100%);
          filter: brightness(0) saturate(100%) invert(97%) sepia(8%) saturate(67%) hue-rotate(339deg) brightness(114%) contrast(100%);
}
.nav__icon {
  cursor: pointer!important;
}

.balanse__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
  cursor: pointer!important;
}

.balanse__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 8px;
  text-align: center;
}

.balanse__action-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 100%;
  background-color: var(--dark-color);
  cursor: pointer!important;
}

.balanse__action-icon:hover {
  background: #46ae8d;
}

.balanse__action-text {
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  color: var(--white-color);
}

.footer {
  margin-top: auto;
  margin-bottom: 20px;
}

.footer__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 14px;
}

@media (max-width: 1449.98px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 991.98px) {
  .container {
    max-width: 970px;
  }
}

@media (max-width: 767.98px) {
  .container {
    max-width: 740px;
  }
}

@media (max-width: 479.98px) {
  .container {
    max-width: none;
  }
}


.select__tokens.is-open {
  top:0;
  opacity: 1;
}

.loader-block {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../img/start.png");
  background-size: cover;
  z-index: 99999999;
}

.loader-block-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.empty-transactions {
  color: black;
}

.form__input:active, .form__input:focus {
  outline: none!important;
}


.form__input-gorup.error {
  border: 1px solid red;
}
.form__input.error {
  border: 1px solid red;
}
.form__file-picture.error {
  border: 1px solid red;
}

.absolute-menu-wrapper {
  position: absolute;
  z-index: 9999999999999999999999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #00000066;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 32px;
  padding-left: 8px;
  padding-right: 8px;
}


.absolute-menu-wrapper-menu-list {
    width: 100%;
    background: white;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    /*padding: 16px;*/
    margin-bottom: 8px;
  /*filter: blur(5px);*/
}
.absolute-menu-wrapper-menu-list-item-top {
  width: 100%;
  height: 42px;
  /*background: white;*/
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: normal;
  color: #3C3C4399;
}
.absolute-menu-wrapper-menu-list-item {
  height: 60px;
  width: 100%;
  /*background: white;*/
  /*border-radius: 13px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #007AFF;
  border-top: 1px solid #2e2b2b30;
  cursor: pointer;
}
.absolute-menu-wrapper-menu-list-item.in-bot {
  width: 100%;
  height: 60px;
  /*background: white;*/
  /*border-radius: 13px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: red;
}
.close-absolute-menu-wrapper {
  width: 100%;
  height: 60px;
  background: white;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #007AFF;
  cursor: pointer;
}

.red-color {
  color: red;

}

.scanner-main-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  width: 100%;
  height: 100vh;
}

.scanner-main-wrapper > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanner-main-wrapper > div > div > div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.balanse__select_main_wrapper {
  position: relative;

}

.balanse__select_wraper {
  position: absolute;
  top: -1px;
  border: 1px solid white;
  width: 100%;
  border-top: unset;
  background: white;
  color: black;
  border-radius: 8px;
}

.balanse__select_item {
  padding: 6px 7px;
  box-sizing: border-box;
  cursor: pointer!important;
}

.beeg-zindex {
  position: relative;
  z-index: 998;
}

.beeg-zindex2 {
  position: relative;
  z-index: 997;
}

.welcome_text {
  font-size: 40px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.welcome_desc {
  text-align: center;
  max-width: 400px;
}

.iframe-wrapper {
  width: 110%;
  flex: 1 1;
  transform: scale(.9);
  margin-left: -20px;
}

input:focus, input:active {
  outline: none!important;
}


/* Можно добавить в ваш style.css */
.form__code {
  -moz-appearance: textfield; /* Firefox */
}

.form__code::-webkit-inner-spin-button,
.form__code::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
